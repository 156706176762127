/*
 * @Author: your name
 * @Date: 2021-11-23 10:19:20
 * @LastEditTime: 2021-12-07 09:56:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\api\general-user\team-building.js
 */
import request from '@/utils/request.js';
/**
 * 获取列表
 * @returns {Promise}
 */
export function getList(data) {
  return request({
    url: '/traffic-construction/safetyTrain/list',
    method: 'post',
    data
  });
}
/**
 * 删除
 * @returns {Promise}
 */
export function deletePlan(data) {
  return request({
    url: '/traffic-construction/safetyTrain/delete',
    method: 'post',
    data
  });
}

/**
 * 新增
 * @returns {Promise}
 */
export function postAdd(data) {
  return request({
    url: '/traffic-construction/safetyTrain/add',
    method: 'post',
    data
  });
}
/**
 * 详情
 * @returns {Promise}
 */
export function getDetail(params) {
  return request({
    url: '/traffic-construction/safetyTrain/getDetail',
    method: 'get',
    params
  });
}
/**
 * 修改
 * @returns {Promise}
 */
export function postEdit(data) {
  return request({
    url: '/traffic-construction/safetyTrain/edit',
    method: 'post',
    data
  });
}
/**
 * 图表
 * @returns {Promise}
 */
export function getPhotoData(data) {
  return request({
    url: '/traffic-construction/safetyTrain/getPhotoData',
    method: 'post',
    data
  });
}
/**
 * 导出
 * @returns {Promise}
 */
export function postExport(data) {
  return request({
    url: '/traffic-construction/safetyTrain/export',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
