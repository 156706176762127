export default function downloadFile(response, name) {
  const blob = new Blob([response.data]);
  let fileName = '';
  if (name) {
    fileName = name;
  } else {
    fileName = getFileName(response);
  }
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(link.href);
};

function getFileName(response) {
  const contentDisposition = response.headers['content-disposition'];
  if (!contentDisposition) {
    return '文件';
  }
  let fileName = '';
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, '');
  }
  fileName = decodeURI(fileName);

  return fileName;
}
