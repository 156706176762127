<!--
 * @Author: your name
 * @Date: 2021-11-11 14:58:25
 * @LastEditTime: 2021-12-28 15:36:01
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\team-building\list-display\listDialog.vue
-->
<template>
  <el-dialog
    width="950px"
    :title="headerText"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" :inline="true" label-width="140px" :model="paramsForm" :rules="rules" v-loading="dialogLoading">
      <el-row>
        <el-col :span="24">
          <el-form-item label="请选择所属项目部" prop="projectDepartment">
            <el-select
              v-model="paramsForm.projectDepartment"
              :disabled="isDisabled"
              placeholder="请选择"
              style="width:300px"
            >
              <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="培训主题" prop="topic">
        <el-input
          v-model="paramsForm.topic"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="培训对象及人数" prop="object">
        <el-input
          v-model="paramsForm.object"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="培训部门或召集人" prop="convener">
        <el-input
          v-model="paramsForm.convener"
          maxlength="20"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="主讲人" prop="presenter">
        <el-input
          v-model="paramsForm.presenter"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="记录整理人" prop="recordOrganizer">
        <el-input
          v-model="paramsForm.recordOrganizer"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="培训时间" prop="trainingTime">
        <el-date-picker
          style="width:300px"
          v-model="paramsForm.trainingTime"
          type="date"
          :disabled="isDisabled"
          placeholder="请选择日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="地点" prop="location">
        <el-input
          v-model="paramsForm.location"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="学时" prop="classHours">
        <el-input
          v-model="paramsForm.classHours"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="培训提纲" prop="outline">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          v-model="paramsForm.outline"
          :disabled="isDisabled"
          maxlength="500"
          placeholder="请输入"
          style="width: 740px"
        ></el-input>
      </el-form-item>
      <el-form-item label="培训评价" prop="evaluation">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          v-model="paramsForm.evaluation"
          maxlength="500"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 740px"
        ></el-input>
      </el-form-item>
      <el-form-item label="附件">
        <div v-if="headerText === '查看详情'">
          <div
            v-for="item in paramsForm.enclosureFileInfoList"
            :key="item.fileId"
            @click="uploadFile(item.fileId)"
            style="cursor:pointer"
          >
            {{ item.fileOriginName }}
          </div>
        </div>
        <upload-multiple-file
          v-else
          v-model="paramsForm.fileId"
          :default-list="defaultList"
          @get-file-data="getFileData"
        ></upload-multiple-file>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="deleteFunc" type="danger" v-if="isDisabled && $check('safety-train_delete')">删 除</el-button>
      <el-button @click="openDetail" type="primary" v-if="isDisabled && $check('safety-train_edit')">修 改</el-button>
      <el-button type="primary" v-if="!isDisabled" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { deletePlan, postAdd, getDetail, postEdit } from '@/api/general-user/team-building.js';
import UploadMultipleFile from 'components/Upload/UploadMultipleFile';
import downloadFileFunc from '@/utils/download-file.js';
import { downloadFile } from '@/api/general-user/internal-data.js';
export default {
  components: {
    UploadMultipleFile
  },
  props: {
    headerText: {
      type: String
    },
    projectList: {
      type: Array
    }
  },
  data() {
    return {
      paramsForm: {
        projectDepartment: '',
        topic: '',
        object: '',
        convener: '',
        presenter: '',
        recordOrganizer: '',
        trainingTime: '',
        location: '',
        classHours: '',
        outline: '',
        evaluation: ''
      },
      visible: false,
      rules: {
        projectDepartment: [{ required: true, message: '请选择所属项目部', trigger: 'change' }],
        trainingTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        topic: [{ required: true, message: '请输入', trigger: 'change' }],
        object: [{ required: true, message: '请输入', trigger: 'change' }],
        convener: [{ required: true, message: '请输入', trigger: 'change' }],
        presenter: [{ required: true, message: '请输入', trigger: 'change' }],
        recordOrganizer: [{ required: true, message: '请输入', trigger: 'change' }],
        location: [{ required: true, message: '请输入', trigger: 'change' }],
        classHours: [{ required: true, message: '请输入', trigger: 'change' }],
        outline: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation: [{ required: true, message: '请输入', trigger: 'change' }]
      },
      detailId: '',
      isDisabled: false,
      dialogLoading: false,
      confirmLoading: false,
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      defaultList: []
    };
  },
  methods: {
    //下载附件
    uploadFile(fileId) {
      downloadFile({ fileId }).then(res => {
        downloadFileFunc(res);
      });
    },
    getFileData(fileIdList) {
      this.paramsForm.enclosureIds = fileIdList.join(',');
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.detailId = '';
      this.paramsForm = this.$options.data().paramsForm;
      this.defaultList = [];
    },
    open(row) {
      this.visible = true;
      if (row?.id) {
        this.getDetail(row.id);
        for (const key in this.rules) {
          this.rules[key][0].required = false;
        }
        this.isDisabled = true;
      } else {
        for (const key in this.rules) {
          this.rules[key][0].required = true;
        }
        this.isDisabled = false;
      }
      // this.$nextTick(() => {
      //   this.paramsForm.orgId = orgId;
      //   this.paramsForm.orgName = orgName;
      //   // this.getGrantUserListData(orgId);
      // });
    },
    // 获取详情
    getDetail(id) {
      this.dialogLoading = true;
      getDetail({ id })
        .then(res => {
          if (!res.success) {
            this.$message.error(res.message);
            return;
          }
          this.paramsForm = res.data;
          this.detailId = res.data.id;
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    deleteFunc() {
      this.$confirm('请确认是否删除该培训记录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deletePlan();
      });
    },
    //删除接口
    deletePlan() {
      deletePlan({ id: this.detailId }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.handleColse();
          this.$emit('on-success');
        }
      });
    },
    openDetail() {
      if (this.paramsForm.enclosureFileInfoList && this.paramsForm.enclosureFileInfoList.length) {
        this.paramsForm.enclosureFileInfoList.forEach(item => {
          item.name = item.fileOriginName;
          item.url = item.filePath;
        });
        this.defaultList = this.paramsForm.enclosureFileInfoList; //附件回显
      }
      this.dialogLoading = true;
      this.isDisabled = false;
      this.$emit('update:headerText', '修改计划');
      for (const key in this.rules) {
        this.rules[key][0].required = true;
      }
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        if (!this.detailId) {
          postAdd(this.paramsForm)
            .then(res => {
              this.$message.success('新增成功！');
              this.handleColse();
              this.$emit('on-success');
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        } else {
          postEdit(this.paramsForm)
            .then(res => {
              this.$message.success('修改成功！');
              this.handleColse();
              this.$emit('on-success');
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-date-picker .el-picker-panel__content {
  height: auto;
}
</style>
